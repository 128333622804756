<div class="row">
  <div [ngClass]="trialFlow ? 'col-7' : 'col-6'">
    <div class="form-group">
      <label for="addressCountry">{{ "country" | i18n }}</label>
      <select
        id="addressCountry"
        class="form-control"
        [(ngModel)]="taxInfo.country"
        required
        name="addressCountry"
        autocomplete="country"
        (change)="changeCountry()"
      >
        <option value="">-- Select --</option>
        <option value="US">United States</option>
        <option value="CN">China</option>
        <option value="FR">France</option>
        <option value="DE">Germany</option>
        <option value="CA">Canada</option>
        <option value="GB">United Kingdom</option>
        <option value="AU">Australia</option>
        <option value="IN">India</option>
        <option value="-" disabled></option>
        <option value="AD">Andorra</option>
        <option value="AR">Argentina</option>
        <option value="AT">Austria</option>
        <option value="BE">Belgium</option>
        <option value="BO">Bolivia, Plurinational State of</option>
        <option value="BR">Brazil</option>
        <option value="BG">Bulgaria</option>
        <option value="CL">Chile</option>
        <option value="CO">Colombia</option>
        <option value="CR">Costa Rica</option>
        <option value="HR">Croatia</option>
        <option value="CY">Cyprus</option>
        <option value="CZ">Czech Republic</option>
        <option value="DK">Denmark</option>
        <option value="DO">Dominican Republic</option>
        <option value="EC">Ecuador</option>
        <option value="EG">Egypt</option>
        <option value="SV">El Salvador</option>
        <option value="EE">Estonia</option>
        <option value="FI">Finland</option>
        <option value="GE">Georgia</option>
        <option value="GR">Greece</option>
        <option value="HK">Hong Kong</option>
        <option value="HU">Hungary</option>
        <option value="IS">Iceland</option>
        <option value="ID">Indonesia</option>
        <option value="IQ">Iraq</option>
        <option value="IE">Ireland</option>
        <option value="IL">Israel</option>
        <option value="IT">Italy</option>
        <option value="JP">Japan</option>
        <option value="KE">Kenya</option>
        <option value="KR">Korea, Republic of</option>
        <option value="LV">Latvia</option>
        <option value="LI">Liechtenstein</option>
        <option value="LT">Lithuania</option>
        <option value="LU">Luxembourg</option>
        <option value="MY">Malaysia</option>
        <option value="MT">Malta</option>
        <option value="MX">Mexico</option>
        <option value="NL">Netherlands</option>
        <option value="NZ">New Zealand</option>
        <option value="NO">Norway</option>
        <option value="PE">Peru</option>
        <option value="PH">Philippines</option>
        <option value="PL">Poland</option>
        <option value="PT">Portugal</option>
        <option value="RO">Romania</option>
        <option value="RU">Russian Federation</option>
        <option value="SA">Saudi Arabia</option>
        <option value="RS">Serbia</option>
        <option value="SG">Singapore</option>
        <option value="SK">Slovakia</option>
        <option value="SI">Slovenia</option>
        <option value="ZA">South Africa</option>
        <option value="ES">Spain</option>
        <option value="SE">Sweden</option>
        <option value="CH">Switzerland</option>
        <option value="TW">Taiwan</option>
        <option value="TH">Thailand</option>
        <option value="TR">Turkey</option>
        <option value="UA">Ukraine</option>
        <option value="AE">United Arab Emirates</option>
        <option value="UY">Uruguay</option>
        <option value="VE">Venezuela, Bolivarian Republic of</option>
        <option value="VN">Viet Nam</option>
      </select>
    </div>
  </div>
  <div [ngClass]="trialFlow ? 'col-5' : 'col-3'">
    <div class="form-group">
      <label for="addressPostalCode">{{ "zipPostalCode" | i18n }}</label>
      <input
        id="addressPostalCode"
        class="form-control"
        type="text"
        name="addressPostalCode"
        [(ngModel)]="taxInfo.postalCode"
        [required]="taxInfo.country === 'US'"
        autocomplete="postal-code"
      />
    </div>
  </div>
  <div class="col-6" *ngIf="organizationId && taxInfo.country !== 'US'">
    <div class="form-group form-check">
      <input
        class="form-check-input"
        id="addressIncludeTaxId"
        name="addressIncludeTaxId"
        type="checkbox"
        [(ngModel)]="taxInfo.includeTaxId"
      />
      <label class="form-check-label" for="addressIncludeTaxId">{{ "includeVAT" | i18n }}</label>
    </div>
  </div>
</div>
<div class="row" *ngIf="organizationId && taxInfo.includeTaxId">
  <div class="col-6">
    <div class="form-group">
      <label for="taxId">{{ "taxIdNumber" | i18n }}</label>
      <input id="taxId" class="form-control" type="text" name="taxId" [(ngModel)]="taxInfo.taxId" />
    </div>
  </div>
</div>
<div class="row" *ngIf="organizationId && taxInfo.includeTaxId">
  <div class="col-6">
    <div class="form-group">
      <label for="addressLine1">{{ "address1" | i18n }}</label>
      <input
        id="addressLine1"
        class="form-control"
        type="text"
        name="addressLine1"
        [(ngModel)]="taxInfo.line1"
        autocomplete="address-line1"
      />
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <label for="addressLine2">{{ "address2" | i18n }}</label>
      <input
        id="addressLine2"
        class="form-control"
        type="text"
        name="addressLine2"
        [(ngModel)]="taxInfo.line2"
        autocomplete="address-line2"
      />
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <label for="addressCity">{{ "cityTown" | i18n }}</label>
      <input
        id="addressCity"
        class="form-control"
        type="text"
        name="addressCity"
        [(ngModel)]="taxInfo.city"
        autocomplete="address-level2"
      />
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <label for="addressState">{{ "stateProvince" | i18n }}</label>
      <input
        id="addressState"
        class="form-control"
        type="text"
        name="addressState"
        [(ngModel)]="taxInfo.state"
        autocomplete="address-level1"
      />
    </div>
  </div>
</div>
